import React from 'react'
import PropTypes from 'prop-types'
import { Section, Container, Heading, StyledLink as Link } from './error.css'
import { Copy } from 'components/typography'

const Error = ({ code, description }) => (
  <Section>
    <Container>
      <Heading glitch={code}>{code}</Heading>
      <Copy as="p" weight="light">{description} Pretend you didn't see it and head back <Link to="/">home</Link>?</Copy>
    </Container>
  </Section>
)

Error.propTypes = {
  code: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default Error
