import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import { Header } from 'components/header'
import Main from 'components/main'
import Error from 'components/error'

const NotFound = (props) => (
  <Layout location={props.location}>
    <Helmet>
      <title>Page not found</title>
    </Helmet>
    <Header />
    <Main>
      <Error code="404" description="Ah shit, a 404." />
    </Main>
  </Layout>
)

NotFound.propTypes = {
  location: PropTypes.object.isRequired
}

export default NotFound
