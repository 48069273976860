import styled, { keyframes } from 'styled-components'
import media from 'constants/breakpoints'
import { Link } from 'gatsby'

const glitchBefore = keyframes`
  // Repeat animation 5 times accross 1/3 of timeline
  0%, 6.60%, 13.20%, 19.80%, 26.40%, 33%, 100% { transform: translate(0) }
  1.32%, 7.92%, 14.52%, 21.12%, 27.72% { transform: translate(-.01em, .01em) }
  2.64%, 9.24%, 15.85%, 22.44%, 29.04% { transform: translate(-.01em, -.01em) }
  3.96%, 10.56%, 17.16%, 23.76%, 30.36% { transform: translate(.01em, .01em) }
  5.28%, 11.88%, 18.48%, 25.08%, 31.68% { transform: translate(.01em, -.01em) }
`

const glitchAfter = keyframes`
  0%, 6.60%, 13.20%, 19.80%, 26.40%, 33%, 100% { transform: translate(0) }
  1.32%, 7.92%, 14.52%, 21.12%, 27.72% { transform: translate(.01em, -.01em) }
  2.64%, 9.24%, 15.85%, 22.44%, 29.04% { transform: translate(.01em, .01em) }
  3.96%, 10.56%, 17.16%, 23.76%, 30.36% { transform: translate(-.01em, -.01em) }
  5.28%, 11.88%, 18.48%, 25.08%, 31.68% { transform: translate(-.01em, .01em) }
`

export const Section = styled.section`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  text-align: center;
  align-content: center;
  padding: 120px 0;
`

export const Heading = styled.h1`
  font-size: 44vw;
  font-weight: 400;
  font-family: var(--serif);
  line-height: 1;
  font-style: normal;

  overflow: hidden;
  position: relative;

  ${media.DESKTOP`
    font-size: 452px;
  `}

  ::before, ::after {
    display: block;
    content: '${(props) => props.glitch}';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.8;
  }
  ::before {
    color: #0ff;
    z-index: -1;
    animation: ${glitchBefore} 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s infinite;
  }
  ::after {
    color: #f0f;
    z-index: -2;
    animation: ${glitchAfter} 3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s infinite;
  }
`

export const Container = styled.div`
  margin: auto;
  width: 80vw;
  max-width: 896px;
`

export const StyledLink = styled(Link)`
  ::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: var(--neutral900);
    transform: scaleX(1) translateZ(0);
    margin-top: -2px;
    transition: transform 0.31s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform-origin: left;
  }

  &:hover::after {
    transform: scaleX(0) translateZ(0);
    transform-origin: right;
  }
`
